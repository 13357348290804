import {Card, CardBody, CardHeader, Image, Link, ScrollShadow} from "@nextui-org/react";
import {GlobalSettings} from "@/configuration/global-settings";
import {Carousel, IconButton} from "@material-tailwind/react";
import {FaAngleLeft, FaAngleRight} from "react-icons/fa";
import SharedServiceInstance from "@/services/shared-service";
import {NEWS_TYPE} from '@/components/helpers/constant.js';
import LoadingCommon from "@/components/shared/loadingCommon";
import {CategoryHelper} from '@/components/helpers/category';
import NextImage from "next/image";
import Swipe from "react-easy-swipe";
import SharedService from "@/services/shared-service";

var _ = require('lodash');

export default function LatestNews({data, number}) {
    const sharedService = SharedServiceInstance;
    const currentCategory = CategoryHelper.getCurrentCategory(NEWS_TYPE.LATEST.code) || NEWS_TYPE.LATEST;

    const cardViews = data.value.map((item, index) => {
        if (index < number) {
            return (
                <Card
                    href={SharedService.getPostPath(item)}
                    as={Link}
                    key={item.id}
                    isBlurred
                    className="border-none bg-background/60 dark:bg-default-100/50 w-full rounded-[5px]"
                    shadow="sm">
                    <CardHeader className="overflow-visible w-full p-0 justify-center items-center">
                        <div className="w-full max-w-sm aspect-video">
                            <Image
                                removeWrapper={true}
                                as={NextImage}
                                width={384}
                                height={214}
                                sizes="(max-width: 768px) 100vw,
                              (max-width: 1200px) 50vw,
                              33vw"
                                alt={item.title} className="rounded-none h-[193px] w-full"
                                src={sharedService.isValidUrl(item.photo) ? `${sharedService.getOptimizeImage(item.photo, 'main-post-view')}` : item.photo ? `${GlobalSettings.Settings.Server}/${item.photo}` : "/webp/chanh-toa.webp"}
                            />
                        </div>
                    </CardHeader>
                    <CardBody className="border-b-1">
                        <div>
                            <p className="text-news-type font-bold space-y-2">{_.head(item.categories)?.name}</p>
                        </div>
                        <p className="text-news line-clamp-2 font-bold space-y-2 h-[50px]">{item.title}</p>

                        <br/>
                        <hr/>
                        <p className="text-news line-clamp-3 italic pt-2">{item.metaDescription}</p>
                    </CardBody>
                </Card>
            );
        }
    });
    let next, prev;
    return (
        <div className="my-5 p-5 bg-white rounded-[5px]">
            <div className="text-3xl font-bold">
                <Link href={"/chuyen-de/" + NEWS_TYPE.LATEST.code}
                      className="text-3xl text-primary">{currentCategory.name.toUpperCase() || currentCategory.name.toUpperCase()}</Link>
            </div>

            {data.total === 0 && <>
                <LoadingCommon/>
            </>}

            <ScrollShadow orientation="vertical" className="flex w-full">
                <div className="md:grid md:grid-cols-5 gap-5 my-5 md:min-w-[1800px] hidden">
                    {cardViews}
                </div>
            </ScrollShadow>
            {/* Mobile layout: start */}
            <div className="md:hidden">
                <Swipe
                    onSwipeLeft={next}
                    onSwipeRight={prev}>
                    <Carousel
                        loop={true}
                        autoplay={true}
                        autoplayDelay={5000}
                        className="rounded-none overflow-hidden"
                        prevArrow={({handlePrev}) => {
                            prev = handlePrev;
                            return (
                                <IconButton
                                    variant="text"
                                    size="md"
                                    onClick={handlePrev}
                                    className="rounded-full bg-gray-600/40 !absolute top-1/4 left-0 -translate-y-2/4"
                                >
                                    <FaAngleLeft className="fill-white text-3xl"/>
                                </IconButton>
                            )
                        }}
                        nextArrow={({handleNext}) => {
                            next = handleNext;
                            return (
                                <IconButton
                                    variant="text"
                                    size="md"
                                    onClick={handleNext}
                                    className="rounded-full bg-gray-600/40 !absolute top-1/4 !right-0 -translate-y-2/4"
                                >
                                    <FaAngleRight className="fill-white text-3xl"/>
                                </IconButton>
                            )
                        }}
                        navigation={({setActiveIndex, activeIndex, length}) => (
                            <div className="hidden absolute bottom-4 left-2/4 z-50 -translate-x-2/4 gap-2">
                                {new Array(length).fill("").map((_, i) => (
                                    <span
                                        key={i}
                                        className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                                            activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
                                        }`}
                                        onClick={() => setActiveIndex(i)}
                                    />
                                ))}
                            </div>
                        )}
                    >
                        {cardViews}
                    </Carousel>
                </Swipe>
            </div>
            {/* Mobile layout: end */}
        </div>
    )
        ;
}